@import '../../styles/colors';
@import '../../styles/variables';

$defaultColor: $colorWhite;
$padding:      10px;

.textInputContainer
{
    position: relative;

    &:before
    {
        content:          '';
        background-color: $defaultColor;
        position:         absolute;
        top:              7px;
        left:             10px;
        bottom:           7px;
        width:            2px;
    }

    .textInput
    {
        display:          block;
        width:            100%;
        color:            $defaultColor;
        border:           0;
        padding:          $padding $padding + 20px $padding $padding + 15px;
        font-family:      $fontBrandonGrotesque;
        font-size:        14px;
        font-weight:      normal;
        box-sizing:       border-box;
        border-bottom:    2px solid $defaultColor;
        background-color: $colorBlue;
        min-height:       42px;

        &::-webkit-input-placeholder
        {
            color: $defaultColor;
        }

        &:-ms-input-placeholder
        {
            color: $defaultColor;
        }

        &::placeholder
        {
            color: $defaultColor;
        }

        &:focus,
        &:not(:placeholder-shown)
        {
            font-weight: bold;
            outline:     none;
        }
    }

    svg
    {
        position:  absolute;
        top:       $padding;
        right:     0;
        max-width: 26px;
    }
}