@font-face
{
    font-family: 'Brandon Grotesque';
    src:         url('/fonts/BrandonGrotesque-Light.woff2') format('woff2'),
                 url('/fonts/BrandonGrotesque-Light.woff') format('woff'),
                 url('/fonts/BrandonGrotesque-Light.ttf') format('truetype');
    font-weight: 300;
    font-style:  normal;
}

@font-face
{
    font-family: 'Brandon Grotesque';
    src:         url('/fonts/BrandonGrotesque-Regular.woff2') format('woff2'),
                 url('/fonts/BrandonGrotesque-Regular.woff') format('woff'),
                 url('/fonts/BrandonGrotesque-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style:  normal;
}

@font-face
{
    font-family: 'Brandon Grotesque';
    src:         url('/fonts/BrandonGrotesque-Medium.woff2') format('woff2'),
                 url('/fonts/BrandonGrotesque-Medium.woff') format('woff'),
                 url('/fonts/BrandonGrotesque-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style:  normal;
}

@font-face
{
    font-family: 'Brandon Grotesque';
    src:         url('/fonts/BrandonGrotesque-Bold.woff2') format('woff2'),
                 url('/fonts/BrandonGrotesque-Bold.woff') format('woff'),
                 url('/fonts/BrandonGrotesque-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style:  normal;
}

@font-face
{
    font-family: 'Audi Type Screen';
    src:         url('/fonts/AudiTypeScreen-Light.woff2') format('woff2'),
                 url('/fonts/AudiTypeScreen-Light.woff') format('woff'),
                 url('/fonts/AudiTypeScreen-Light.ttf') format('truetype');
    font-weight: 300;
    font-style:  normal;
}

@font-face
{
    font-family: 'MaxTF';
    src:         url('/fonts/MaxTF-Regular.woff2') format('woff2'),
                 url('/fonts/MaxTF-Regular.woff') format('woff'),
                 url('/fonts/MaxTF-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style:  normal;
}
