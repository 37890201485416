@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';

.videoOverlayContainer
{
    @include shadow;

    position:         relative;
    z-index:          2;
    display:          flex;
    flex-flow:        row nowrap;
    justify-content:  center;
    width:            845px;
    max-width:        90%;
    max-height:       80%;
    margin:           auto;
    padding:          30px 10px;
    border-radius:    6px;
    background-color: $colorWhite;

    @media (min-width: $mobileBreakpoint)
    {
        padding: 30px 20px;
    }

    .videoOverlayContainerLeft
    {
        display:     flex;
        flex-flow:   column;
        align-items: flex-start;
        max-width:   100%;

        h2
        {
            font-size:   22px;
            font-family: $fontBrandonGrotesque;
            font-weight: $fontWeightLight;

            @media (min-width: $mobileBreakpoint)
            {
                font-size: 32px;
            }
        }

        .videoOverlayInnerContainer
        {
            @include shadow;

            position:     relative;
            margin-top:   20px;
            width:        700px;
            max-width:    100%;
            aspect-ratio: 16 / 9;

            .videoOverlaySkipButtonContainer
            {
                position:  absolute;
                top:       -10px;
                right:     0;
                transform: translateY(-100%);
            }
        }
    }
}

.multipleChoiceSelectorButtonContainer
{
    position:   fixed;
    z-index:    1;
    bottom:     5vh;
    width:      320px;
    margin-top: 100px;
    max-width:  90%;

    .multipleChoiceSelectorProgressContainer
    {
        margin-top: 20px;

        @media (min-width: $mobileBreakpoint)
        {
            margin-top: 50px;
        }
    }
}
