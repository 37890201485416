@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.progressContainer
{
    display:   flex;
    flex-flow: column nowrap;
    width:     100%;
    max-width: 90vw;

    .progress
    {
        width:            100%;
        height:           2px;
        overflow:         hidden;
        background-color: $colorGrayLight;

        .percentage
        {
            height:           100%;
            background-color: $colorGray;
            transition:       width .3s linear;
        }
    }

    .progressText
    {
        color:       $colorGray;
        font-size:   12px;
        font-family: $fontBrandonGrotesque;
        margin-top:  10px;
        text-align:  center;
    }
}