@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';

.multipleChoiceSelectorContainer
{
    display:       flex;
    flex-flow:     column nowrap;
    align-items:   center;
    max-width:     100%;
    width:         1020px;

    .multipleChoiceSelector
    {
        position:         relative;
        display:          flex;
        flex-flow:        column nowrap;
        box-sizing:       border-box;
        width:            100%;
        border-radius:    $borderRadius;
        background-color: $colorWhite;
        padding:          20px 40px 50px 40px;

        @include shadow;

        @media (min-width: $mobileBreakpoint)
        {
            height:  400px;
            padding: 30px 60px 70px 60px;
        }

        .multipleChoiceSelectorTitleContainer
        {
            display:         flex;
            justify-content: space-between;
        }

        .multipleChoiceSelectorItems
        {
            display:         flex;
            flex-flow:       row wrap;
            align-items:     flex-start;
            justify-content: center;
            margin:          0 -10px;
        }

        .multipleChoiceSelectorSkipAnswerContainer
        {
            position:  absolute;
            left:      50%;
            bottom:    -70px;
            transform: translateX(-50%);

            .multipleChoiceSelectorSkipAnswer
            {
                color:       $colorGrayDarker;
                font-size:   20px;
                font-family: $fontBrandonGrotesque;
                font-weight: $fontWeightMedium;
                border:      none;
                background:  none;
                user-select: none;

                &[disabled]
                {
                    color: $colorGrayLight;
                }

                &.multipleChoiceSelectorSkipAnswerActive
                {
                    color: $colorBlue;
                }
            }
        }
    }

    .multipleChoiceSelectorButtonContainer
    {
        z-index:    1;
        width:      320px;
        margin-top: 100px;
        max-width:  90%;

        .multipleChoiceSelectorProgressContainer
        {
            margin-top: 20px;

            @media (min-width: $mobileBreakpoint)
            {
                margin-top: 50px;
            }
        }
    }
}
