@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.footer
{
    padding:         15px 0;
    width:           100%;
    display:         flex;
    flex-flow:       column nowrap;
    align-items:     center;
    justify-content: flex-end;

    &.blueFooterLink
    {
        background-color: $colorWhite;

        .footerLinks
        {
            a.footerLink
            {
                color: $colorBlue;
            }
        }
    }

    .footerLinks
    {
        display:       flex;
        flex-flow:     row wrap;
        margin-bottom: 10px;

        a.footerLink
        {
            display:         inline-block;
            margin-left:     20px;
            font-size:       12px;
            font-family:     $fontMaxTF;
            color:           $colorWhite;
            text-decoration: none;

            &:first-child
            {
                margin-left: 0;
            }

            &:hover
            {
                text-decoration: underline;
            }
        }
    }
}
