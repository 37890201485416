$borderRadius:         6px;
$headerHeight:         75px;
$containerWidth:       1140px;
$innerContainerWidth:  263px;

$fontBrandonGrotesque: 'Brandon Grotesque', sans-serif;
$fontAudiTypeScreen:   'Audi Type Screen', sans-serif;
$fontMaxTF:            'MaxTF', sans-serif;
$fontAwesome:          'Font Awesome 5 Free';
$fontWeightLight:      300;
$fontWeightMedium:     500;

$mobileBreakpoint:     992px;

:export
{
    borderRadius:        $borderRadius;
    headerHeight:        $headerHeight;
    innerContainerWidth: $innerContainerWidth;
}
