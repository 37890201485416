@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';

$itemHeight:      60px;
$transitionDelay: .5s;

.pointSelectorRow
{
    height:     0;
    opacity:    0;
    transition: height .3s linear $transitionDelay,
                opacity .3s linear $transitionDelay,
                box-shadow .3s linear;

    @include no-focus;

    @media (max-width: $mobileBreakpoint)
    {
        .pointSelectorRowContent
        {
            .pointSelectorRowTitle
            {
                position: absolute;
            }
        }
    }

    &.pointSelectorRowActive
    {
        height:     $itemHeight;
        opacity:    1;
        transition: height .3s linear,
                    opacity .3s linear,
                    box-shadow .3s linear $transitionDelay;

        @include shadow;

        .pointSelectorRowContent
        {
            .pointSelectorRowTitle
            {
                color:       $colorWhite;
                font-weight: $fontWeightMedium;
                animation:   appear 1s linear;

                @media (max-width: $mobileBreakpoint)
                {
                    position:  absolute;
                    z-index:   1;
                    top:       -80px;
                    font-size: 20px;
                    color:     $colorBlack;
                }
            }
        }

        & ~ .pointSelectorRow
        {
            height:  $itemHeight;
            opacity: 1;
        }
    }

    .pointSelectorRowBackground
    {
        position:      absolute;
        left:          0;
        width:         calc(100vw - 45px);
        height:        $itemHeight;
        border-bottom: 1px solid $colorGrayLight;
        transition:    background-color .1s linear;

        &.pointSelectorRowBackgroundActive
        {
            width:            100vw;
            border-bottom:    0;
            opacity:          1;
            background-color: $colorBlue;
        }
    }

    .pointSelectorRowContent
    {
        position: relative;
        display:  flex;
        height:   100%;

        .pointSelectorRowTitle
        {
            flex:           0 0 120px;
            height:         100%;
            font-size:      15px;
            color:          $colorGrayDark;
            font-family:    $fontBrandonGrotesque;
            font-weight:    $fontWeightLight;
            letter-spacing: -.5px;
            line-height:    $itemHeight;
            transition:     color .3s linear,
                            font-weight .3s linear;

            @media (min-width: $mobileBreakpoint)
            {
                flex:      0 0 170px;
                font-size: 20px;
            }
        }

        .pointSelectorRowButtons
        {
            display:         flex;
            justify-content: space-between;
            width:           100%;
            opacity:         0;
            pointer-events:  none;
            transition:      opacity .3s linear $transitionDelay;

            &.pointSelectorRowButtonsActive
            {
                opacity:        1;
                pointer-events: inherit;
            }
        }
    }
}

@keyframes appear
{
    0%
    {
        opacity: 0;
    }
    75%
    {
        opacity: 0;
    }
    100%
    {
        opacity: 1;
    }
}
