@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.headline
{
    color:       $colorBlack;
    font-family: $fontAudiTypeScreen;
    font-size:   32px;
    font-weight: bold;
    margin:      0 0 24px 0;

    &:first-of-type
    {
        margin-top: 40px;
    }

    &.headlineLarge
    {
        font-size: 48px;
    }
}

.headlineWrapper
{
    position: relative;
    width:    100%;
}