$colorBlue:          #00baec;
$colorBlue-trans-5:  rgba(0, 186, 236, .1);
$colorBlack:         #000000;
$colorGrayDarker:    #4a4a4a;
$colorGrayDark:      #9b9b9b;
$colorGray:          #a0a0a0;
$colorGrayLight:     #dddee0;
$colorGrayLighter:   #f7f8fa;
$colorWhite:         #ffffff;
$colorWhiteDark:     #f3f6f9;
$colorBlack-trans-4: rgba(0, 0, 0, .05);

:export
{
    colorBlack:         $colorBlack;
    colorBlack-trans-4: $colorBlack-trans-4;
    colorBlue:          $colorBlue;
    colorBlue-trans-5:  $colorBlue-trans-5;
    colorGrayLight:     $colorGrayLight;
    colorGrayLighter:   $colorGrayLighter;
    colorWhite:         $colorWhite;
    colorWhiteDark:     $colorWhiteDark;
}