@import 'colors';

@mixin no-focus
{
    outline:            none;
    -webkit-box-shadow: none;
    -moz-box-shadow:    none;
    box-shadow:         none;
}

@mixin no-default-focus
{
    outline: none;
}

@mixin focus
{
    outline:            none;
    -webkit-box-shadow: 0 0 3px 1px $colorBlue;
    -moz-box-shadow:    0 0 3px 1px $colorBlue;
    box-shadow:         0 0 3px 1px $colorBlue;
}

@mixin focus-grey
{
    outline:            none;
    -webkit-box-shadow: 0 0 3px 1px $colorGray;
    -moz-box-shadow:    0 0 3px 1px $colorGray;
    box-shadow:         0 0 3px 1px $colorGray;
}

@mixin focus-inverted
{
    outline:            none;
    -webkit-box-shadow: 0 0 3px 1px $colorWhite;
    -moz-box-shadow:    0 0 3px 1px $colorWhite;
    box-shadow:         0 0 3px 1px $colorWhite;
}

@mixin shadow
{
    -webkit-box-shadow: 0 4px 20px 0 $colorBlack-trans-4;
    -moz-box-shadow:    0 4px 20px 0 $colorBlack-trans-4;
    box-shadow:         0 4px 20px 0 $colorBlack-trans-4;
}

@mixin shadow-focus
{
    outline:            none;
    -webkit-box-shadow: 0 4px 20px 0 $colorBlue-trans-5;
    -moz-box-shadow:    0 4px 20px 0 $colorBlue-trans-5;
    box-shadow:         0 4px 20px 0 $colorBlue-trans-5;
}