@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/variables';

*
{
    margin:  0;
    padding: 0;
}

html
{
    height: 100%;
}

body
{
    margin:                  0;
    padding:                 0;
    height:                  100%;
    min-width:               320px;
    overflow-x:              hidden;
    font-family:             -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
                             "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
                             sans-serif;
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code
{
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button
{
    cursor: pointer;
}

.login
{
    box-sizing:       border-box;
    padding:          0 15px;
    display:          flex;
    flex-flow:        column;
    align-items:      center;
    justify-content:  center;
    width:            100vw;
    min-height:       100vh;
    overflow:         auto;
    background-color: $colorBlue;

    .innerLoginContainer
    {
        padding:   30px 0;
        width:     500px;
        max-width: 100%;

        .logoBig
        {
            min-height:      100px;
            height:          15vh;
            background:      url('./assets/images/logo.svg') no-repeat bottom;
            overflow:        hidden;
            text-indent:     -99999px;
            background-size: contain;
        }

        .loginForm
        {
            margin-top: 10vh;

            input,
            select
            {
                margin-top: 3vh;
            }

            button
            {
                margin-top: 6vh;
            }

            .errorContainer
            {
                display:         flex;
                flex-flow:       column nowrap;
                align-items:     center;
                justify-content: center;

                .errorMessage
                {
                    color:       $colorWhite;
                    font-size:   16px;
                    font-family: $fontBrandonGrotesque;
                    text-align:  center;
                }

                .iconContainer
                {
                    height:     100px;
                    margin-top: 45px;
                }

                button
                {
                    width:     260px;
                    max-width: 100%;
                }
            }

            .loginText
            {
                color:         $colorWhite;
                text-align:    center;
                margin-bottom: 5vh;
            }
        }
    }
}

.main
{
    position:   relative;
    display:    flex;
    flex-flow:  column nowrap;
    height:     auto;
    min-height: 100vh;
    box-sizing: border-box;
    padding:    0 15px;

    &.noFooter
    {
        .container
        {
            margin: 0 -15px;
        }
    }

    .container
    {
        flex:             1 0 auto;
        display:          flex;
        flex-flow:        column nowrap;
        align-items:      center;
        justify-content:  center;
        margin:           0 -15px;
        padding:          20px 5px;
        overflow:         hidden;
        background-color: $colorWhiteDark;

        @media (min-width: $mobileBreakpoint)
        {
            padding: 20px 15px;
        }

        &.notCentered
        {
            justify-content: inherit;
        }

        h1
        {
            margin-bottom: 30px;
        }

        h1,
        p
        {
            color:       $colorBlue;
            font-family: $fontMaxTF;
        }
    }

    .textContainer
    {
        margin:     0 auto 100px auto;
        width:      $containerWidth;
        max-width:  100%;
        text-align: left;

        ul
        {
            padding-left: 30px;
        }
    }
}
