@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.overviewDashboardContainer
{
    display:     flex;
    flex-flow:   column nowrap;
    align-items: center;
    max-width:   100%;
    width:       810px;

    .overviewDashboard
    {
        display:          flex;
        flex-flow:        column nowrap;
        box-sizing:       border-box;
        width:            100%;
        border-radius:    $borderRadius;
        background-color: $colorWhite;
        padding:          20px 40px 50px 40px;

        @include shadow;

        @media (min-width: $mobileBreakpoint)
        {
            padding: 30px 60px 70px 60px;
        }

        .overviewDashboardItems
        {
            flex:            auto;
            display:         flex;
            flex-flow:       row wrap;
            justify-content: center;
            margin:          0 -25px;
        }
    }

    .buttonContainer
    {
        margin-top: 50px;
        width:      260px;

        @media (min-width: $mobileBreakpoint)
        {
            margin-top: 100px;
        }
    }
}
