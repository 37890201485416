@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.uploadLoadingOverlayContainer
{
    display: none;

    &.uploadLoadingOverlayContainerVisible
    {
        display: block;
    }

    .uploadLoadingOverlay
    {
        position:         absolute;
        z-index:          1;
        top:              50%;
        left:             50%;
        width:            850px;
        max-width:        100%;
        height:           200px;
        transform:        translateY(-50%) translateX(-50%);
        display:          flex;
        flex-flow:        column;
        align-items:      center;
        justify-content:  space-between;
        overflow:         hidden;
        box-sizing:       border-box;
        border-radius:    6px;
        background-color: $colorWhite;
        padding:          20px 40px 50px 40px;
        box-shadow:       0 4px 20px 0 $colorBlack-trans-4;

        h2
        {
            margin-right: auto;
        }

        .uploadLoadingOverlayTrack
        {
            position:         relative;
            width:            100%;
            height:           4px;
            margin-top:       -30px;
            border-radius:    1px;
            background-color: $colorGrayDarker;

            .uploadLoadingOverlayTrackBar
            {
                position:         absolute;
                top:              -1px;
                left:             0;
                width:            0;
                height:           6px;
                border-radius:    2px;
                background-color: $colorBlue;
                transition:       width .1s linear;
            }
        }
    }

    .uploadLoadingOverlayBackdrop
    {
        position:        fixed;
        top:             0;
        left:            0;
        width:           100vw;
        height:          100vh;
        backdrop-filter: blur(10px);
    }
}
