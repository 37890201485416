@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.dashboardItem
{
    position:        relative;
    display:         flex;
    flex-flow:       column nowrap;
    align-items:     center;
    flex:            1 1 auto;
    width:           110px;
    min-width:       110px;
    height:          100px;
    min-height:      100px;
    padding:         25px 0 15px 0;
    margin:          35px 25px 0 25px;
    border-radius:   $borderRadius;
    color:           $colorBlack;
    border:          0;
    background:      none;
    text-decoration: none;

    @media (min-width: $mobileBreakpoint)
    {
        width:      150px;
        min-width:  150px;
        height:     140px;
        min-height: 140px;
    }

    @include shadow;

    &:focus
    {
        @include focus;
    }

    &.dashboardItemActive
    {
        color:            $colorWhite;
        background-color: $colorBlue;

        svg
        {
            g,
            path
            {
                stroke: $colorWhite;
            }
        }
    }

    &.dashboardItemDisabled
    {
        opacity:        .2;
        pointer-events: none;

        &:focus
        {
            @include no-focus;
        }
    }

    svg
    {
        g,
        path
        {
            stroke: $colorBlack;
        }
    }

    h2
    {
        margin-top: 24px;

        @media (min-width: $mobileBreakpoint)
        {
            margin-top: 30px;
        }
    }

    span
    {
        display:        block;
        margin-top:     -5px;
        letter-spacing: 1.5px;
        font-size:      12px;
        font-family:    $fontBrandonGrotesque;
        font-weight:    $fontWeightLight;

        @media (min-width: $mobileBreakpoint)
        {
            font-size: 15px;
        }
    }
}
