@import '../../styles/colors';
@import '../../styles/variables';

.title
{
    font-family: $fontBrandonGrotesque;
    font-weight: $fontWeightLight;

    &.titleUppercase
    {
        text-transform: uppercase;
    }
}

.titleMain
{
    opacity:        .7;
    font-size:      24px;
    letter-spacing: 2.6px;

    @media (min-width: $mobileBreakpoint)
    {
        font-size: 32px;
    }
}

.titleCard
{
    font-size:      20px;
    letter-spacing: 2.8px;
}