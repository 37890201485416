@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';

.multiplePointSelectorLabel
{
    flex:            0 1 (100% / 7);
    display:         flex;
    flex-flow:       column;
    align-items:     center;
    justify-content: flex-end;
    box-sizing:      border-box;
    font-size:       18px;
    font-family:     $fontBrandonGrotesque;
    font-weight:     $fontWeightMedium;
    text-align:      center;

    .multiplePointSelectorLabelTitle
    {
        display: none;

        @media (min-width: $mobileBreakpoint)
        {
            display: block;
        }
    }

    .multiplePointSelectorLabelValue
    {
        display:     block;
        color:       $colorGray;
        font-size:   16px;
        font-weight: $fontWeightLight;
        margin-top:  10px;
    }
}