@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';

.multipleChoiceSelectorChoice
{
    display:         flex;
    align-items:     center;
    justify-content: center;
    padding:         25px 10px;
    margin:          25px 10px 0 10px;
    border-radius:   $borderRadius;
    color:           $colorGrayDarker;
    font-size:       16px;
    font-family:     $fontBrandonGrotesque;
    border:          0;
    background:      none;
    min-width:       80px;

    @include shadow;

    @media (min-width: $mobileBreakpoint)
    {
        height: 100px;
        width:  calc(20% - 20px);
    }

    &:focus
    {
        @include focus;
    }

    &:disabled
    {
        cursor:           default;
        color:            $colorGray;
        background-color: $colorGrayLighter;
    }

    &.multipleChoiceSelectorChoiceActive
    {
        color:            $colorWhite;
        background-color: $colorBlue;

        &:disabled
        {
            color:            $colorGray;
            background-color: $colorBlue-trans-5;
        }
    }
}