@import '../../styles/colors';
@import '../../styles/variables';

.headerItem
{
    display:     flex;
    align-items: center;
    margin-left: 40px;
    font-family: $fontAudiTypeScreen;
    font-weight: $fontWeightLight;

    svg
    {
        width: 15px;

        path
        {
            stroke: $colorBlue;
        }
    }

    span
    {
        font-size:      12px;
        letter-spacing: .5px;
        margin-left:    10px;
        white-space:    nowrap;
    }
}