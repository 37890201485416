@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';

.colorButton
{
    display:          flex;
    flex-flow:        row nowrap;
    justify-content:  center;
    padding:          11px 20px;
    width:            100%;
    border:           0;
    border-radius:    $borderRadius;
    font-family:      $fontBrandonGrotesque;
    font-size:        16px;
    text-transform:   lowercase;
    letter-spacing:   2px;
    transition:       padding .3s linear,
                      max-height .3s linear;
    color:            $colorWhite;
    background-color: $colorBlue;

    &:focus
    {
        @include focus;

        font-weight: bold;
    }

    &:hover,
    &:active
    {
        font-weight: bold;
    }

    &:disabled
    {
        display:          none;
        color:            $colorGrayLight;
        background-color: $colorGrayLighter;

        @media (min-width: $mobileBreakpoint)
        {
            display: block;
        }
    }

    &.colorButtonHidden
    {
        padding:    0;
        max-height: 0;
        overflow:   hidden;
    }

    &.colorButtonShadow
    {
        @include shadow;
    }

    &.colorButtonInverted
    {
        color:            $colorBlue;
        background-color: $colorWhite;

        &:focus
        {
            @include focus-inverted;

            font-weight: bold;
        }

        .iconContainer
        {
            svg
            {
                fill: $colorBlue;
            }
        }
    }

    &.showIcon
    {
        .iconContainer
        {
            max-width: 40px;
        }
    }

    .iconContainer
    {
        max-width:  0;
        overflow:   hidden;
        transition: max-width .3s linear;

        &.rotatingIcon
        {
            svg
            {
                animation: spin 5s linear infinite;
            }
        }

        svg
        {
            fill:         $colorWhite;
            width:        20px;
            height:       20px;
            margin-right: 20px;
        }
    }
}

@keyframes spin
{
    from
    {
        transform: rotate(0deg);
    }
    to
    {
        transform: rotate(359deg);
    }
}