@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.copyright
{
    display:     inline-block;
    white-space: nowrap;
    font-family: $fontMaxTF;
    color:       $colorWhite;

    &.blueCopyright
    {
        color: $colorBlue;
    }
}
