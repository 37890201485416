@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';

.pointSelector
{
    position:         relative;
    flex:             0 1 100%;
    text-align:       center;
    height:           calc(100% + 8px);
    margin-top:       -4px;
    background-color: $colorWhite;
    border:           0;

    &:first-child
    {
        border-radius: $borderRadius 0 0 $borderRadius;
    }

    &:last-child
    {
        border-radius: 0 $borderRadius $borderRadius 0;
    }

    &:not(:last-child)
    {
        &:after
        {
            position:    absolute;
            content:     '';
            top:         5px;
            bottom:      5px;
            right:       0;
            border-left: 1px solid $colorGrayLight;
        }
    }

    &:focus
    {
        @include no-focus;

        .pointSelectorCircle
        {
            @include focus;
        }
    }

    &:disabled
    {
        cursor: default;

        .pointSelectorCircle
        {
            background-color: $colorGrayLight;
        }
    }

    &.pointSelectorActive
    {
        background-color: $colorBlue;
    }

    .pointSelectorCircle
    {
        display:       inline-block;
        width:         30px;
        height:        30px;
        border-radius: 16px;
        border:        1px solid $colorBlue;

        &.pointSelectorCircleSelected
        {
            background-color: $colorBlue;
        }
    }
}