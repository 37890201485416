@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.alertBoxManager
{
    position:  fixed;
    top:       20px;
    right:     40px;
    z-index:   $zIndexOverlay;
    display:   flex;
    flex-flow: column;
    max-width: 80vw;

    @media (min-width: 2050px)
    {
        right:     50%;
        transform: translateX(1000px);
    }
}
