@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';

.multiplePointSelectorContainer
{
    display:     flex;
    flex-flow:   column nowrap;
    align-items: center;
    margin:      0 -15px;
    width:       100vw;
    max-width:   100%;

    .multiplePointSelector
    {
        display:       flex;
        flex-flow:     column nowrap;
        width:         100%;
        max-width:     1720px;
        margin-bottom: 150px;

        .multiplePointSelectorLabels
        {
            display:         flex;
            flex-flow:       row nowrap;
            justify-content: flex-end;
            box-sizing:      border-box;
            min-width:       100%;
            height:          40px;

            @media (min-width: $mobileBreakpoint)
            {
                padding-left: 170px;
                height:       110px;
                margin-top:   10vh;
            }
        }

        .multiplePointSelectorItems
        {
            margin-top: 12px;

            .multiplePointSelectorBackground
            {
                pointer-events:      none;
                position:            fixed;
                top:                 calc(10vh + 370px);
                left:                0;
                right:               45px;
                bottom:              0;
                background:          rgba(255, 255, 255, 1);
                background:          -moz-linear-gradient(45deg, rgba(243, 246, 249, 1) 11%, rgba(243, 246, 249, 0.8) 29%, rgba(255, 255, 255, 0) 100%);
                background:          -webkit-gradient(left bottom, right top, color-stop(11%, rgba(243, 246, 249, 1)), color-stop(29%, rgba(243, 246, 249, 0.8)), color-stop(100%, rgba(255, 255, 255, 0)));
                background:          -webkit-linear-gradient(45deg, rgba(243, 246, 249, 1) 11%, rgba(243, 246, 249, 0.8) 29%, rgba(255, 255, 255, 0) 100%);
                background:          -o-linear-gradient(45deg, rgba(243, 246, 249, 1) 11%, rgba(243, 246, 249, 0.8) 29%, rgba(255, 255, 255, 0) 100%);
                background:          -ms-linear-gradient(45deg, rgba(243, 246, 249, 1) 11%, rgba(243, 246, 249, 0.8) 29%, rgba(255, 255, 255, 0) 100%);
                background:          linear-gradient(45deg, rgba(243, 246, 249, 1) 11%, rgba(243, 246, 249, 0.8) 29%, rgba(255, 255, 255, 0) 100%);
                filter:              progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3f6f9', endColorstr='#ffffff', GradientType=1);
                background-position: bottom;
                background-repeat:   no-repeat;
                background-size:     100%;
            }

            .multiplePointSelectorBullets
            {
                position:    absolute;
                top:         250px;
                right:       15px;
                display:     flex;
                flex-flow:   column nowrap;
                align-items: flex-end;
                margin-top:  80px;

                @media (min-width: 768px)
                {
                    margin-top: 0;
                }

                @media (min-width: $mobileBreakpoint)
                {
                    top: calc(10vh + 320px);
                }

                div
                {
                    margin-bottom: 10px;
                }

                .multiplePointSelectorBullet
                {
                    display:       block;
                    text-indent:   -9999px;
                    overflow:      hidden;
                    width:         12px;
                    height:        12px;
                    margin-top:    12px;
                    border-radius: 7px;
                    border:        1px solid $colorGray;
                    background:    none;
                    transition:    background-color .3s linear;

                    &:focus
                    {
                        @include focus-grey
                    }

                    &.multiplePointSelectorBulletActive
                    {
                        background-color: $colorGray;
                    }
                }
            }
        }
    }

    .multiplePointSelectorButtonContainer
    {
        position:   fixed;
        z-index:    1;
        bottom:     5vh;
        width:      320px;
        margin-top: 100px;
        max-width:  90%;

        .multiplePointSelectorProgressContainer
        {
            margin-top: 20px;

            @media (min-width: $mobileBreakpoint)
            {
                margin-top: 50px;
            }
        }
    }
}
