@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.header
{
    position:        relative;
    flex:            0 0 auto;
    box-sizing:      border-box;
    display:         flex;
    align-items:     stretch;
    justify-content: space-between;
    min-height:      $headerHeight;
    padding:         10px 5px;
    margin:          0 -15px;

    @media (min-width: $mobileBreakpoint)
    {
        padding: 10px 15px;
    }

    @include shadow;

    a.headerLogo
    {
        flex:         0 1 auto;
        margin-right: 20px;

        svg
        {
            width:      auto;
            max-height: $headerHeight;
            max-width:  100%;

            path
            {
                fill: $colorBlue
            }
        }
    }

    .headerNavigationContent
    {
        flex:            1 1 auto;
        display:         flex;
        flex-flow:       row wrap;
        align-items:     center;
        justify-content: flex-end;
        margin-left:     -40px;
    }
}
