@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.paragraph
{
    color:       $colorBlack;
    font-size:   15px;
    font-family: $fontAudiTypeScreen;
    margin:      0 0 20px 0;
    line-height: 21px;

    a
    {
        color:           $colorBlue;
        text-decoration: none;

        &:hover
        {
            text-decoration: underline;
        }
    }
}