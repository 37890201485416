@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';

.alertBox
{
    position:      relative;
    display:       flex;
    flex-flow:     row nowrap;
    align-items:   center;
    max-width:     500px;
    padding:       15px 20px;
    margin-bottom: 20px;
    opacity:       0;
    transition:    opacity .3s linear;
    white-space:   pre-wrap;

    &.alertBoxSuccess
    {
        opacity:          1;
        background-color: #bcf5bc;

        .alertBoxCloseButton
        {
            background-color: #bcf5bc;
        }

        .alertBoxText
        {
            color: $colorBlack;
        }

        .alertBoxIcon
        {
            border-right: 1px solid $colorBlack;
        }

        svg > path
        {
            fill: $colorBlack;
        }
    }

    &.alertBoxError
    {
        opacity:          1;
        background-color: #f77975;

        .alertBoxCloseButton
        {
            background-color: #f77975;
        }

        .alertBoxText
        {
            color: $colorWhite;
        }

        .alertBoxIcon
        {
            border-right: 1px solid $colorWhite;
        }
    }

    .alertBoxIcon
    {
        width:         20px;
        height:        20px;
        min-width:     20px;
        padding-right: 20px;
        border-right:  1px solid $colorWhite;

        @media (min-width: 768px)
        {
            width:     40px;
            height:    40px;
            min-width: 40px;
        }

        svg
        {
            max-width:  100%;
            max-height: 100%;
        }
    }

    .alertBoxText
    {
        margin-left: 20px;

        strong
        {
            display: block;
        }
    }

    .alertBoxCloseButton
    {
        position:         absolute;
        top:              -10px;
        right:            -10px;
        width:            30px;
        height:           30px;
        border-radius:    15px;
        border:           none;
        background-color: #f77975;

        svg
        {
            max-width:  80%;
            max-height: 80%;
        }
    }
}
